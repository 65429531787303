import { axios } from '@/utils/request';
/**
 * 分页查询菜单
 */
export function getMenuList(parameter) {
  return axios({
    url: '/sysMenu/list',
    method: 'get',
    params: parameter
  });
}
/**
 * 添加修改菜单
 */
export function editMenu(parameter) {
  return axios({
    url: '/sysMenu/addOrEdit',
    method: 'post',
    data: parameter
  });
}
/**
 * 删除菜单
 */
export function deleteMenu(parameter) {
  return axios({
    url: '/sysMenu/delete',
    method: 'post',
    data: parameter
  });
}
/**
 * 选择父级节点
 */
export function getMenuTree(parameter) {
  return axios({
    url: '/sysMenu/tree',
    method: 'get',
    params: parameter
  });
}

