<template>
  <div>
    <div class='title'>菜单管理</div>
    <el-form :inline='true' size='small' class='demo-form-inline'>
      <el-form-item>
        <el-input v-model.trim='queryParam.name' placeholder='菜单名称/编码'></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim='queryParam.application' placeholder='层级'></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' @click="getMenuList">查询</el-button>
        <el-button @click="resetList">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-box">
      <div class="operate-btn">
        <el-button size="small" type='primary' icon="el-icon-plus" @click="$refs.menuManaForm.init()">新建</el-button>
        <div>
          <el-button size="small" @click="isExpandAll(true)">展开所有</el-button>
          <el-button size="small" @click="isExpandAll(false)">收起所有</el-button>
        </div>
      </div>
      <el-table
        stripe
        size="medium"
        v-loading='loading'
        ref="treeTable"
        row-key="id"
        :data="tableData"
        :expand-row-keys='expandRowList'
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        style='width: 100%'>
        <el-table-column
          type='index'
          label='序号'
          align='center'
          width='80'>
        </el-table-column>
        <el-table-column
          prop='name'
          label='菜单名称'
          sortable
          width='240'>
        </el-table-column>
        <el-table-column
          prop='code'
          label='菜单编号'
          align='center'
          sortable
          width='180'>
          <template slot-scope='{ row }'>{{ row.code || "--"}}</template>
        </el-table-column>
        <el-table-column
          prop='pCode'
          align='center'
          width='180'
          label='菜单父编号'>
          <template slot-scope='{ row }'>{{ row.pCode || "--"}}</template>
        </el-table-column>
        <el-table-column
          prop='router'
          sortable
          width='200'
          align='center'
          label='请求地址'>
        </el-table-column>
        <el-table-column
          prop='sort'
          sortable
          align='center'
          label='排序'>
        </el-table-column>
        <el-table-column
          prop='application'
          sortable
          align='center'
          label='层级'>
          <template slot-scope='{ row }'>{{ row.application || "--"}}</template>
        </el-table-column>
        <el-table-column
          prop='type'
          sortable
          width='180'
          align='center'
          label='是否是菜单'>
          <template slot-scope="{ row }">{{ row.type === 1 ? "是" : "否"}}</template>
        </el-table-column>
        <el-table-column
          prop='visible'
          sortable
          align='center'
          label='状态'>
          <template slot-scope="{ row }">{{ row.visible == 'Y' ? "启用" : "未启用"}}</template>
        </el-table-column>
        <el-table-column
          label='操作'
          fixed='right'
          align='center'
          width='140'>
          <template slot-scope='{ row }'>
            <div class="comm-operation">
              <span class="edit-btn mr-16" @click='$refs.menuManaForm.init(row)'>修改</span>
              <span class="delete-btn" slot='reference' @click="handleDelete(row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增/编辑 对话框 -->
    <menuManaForm ref="menuManaForm" @back="handleBack"/>
  </div>
</template>

<script>
import { getMenuList, deleteMenu } from '@/api/main/system/menuMana'
import menuManaForm from '@/views/main/basicPage/system/menuMana/menuManaForm';
export default {
  name: 'userMana',
  data() {
    return {
      queryParam: {
        name: '',
        application: '',
      },
      loading: false,
      expandRowList: [],
      tableData: [],
    }
  },
  components: {menuManaForm},
  created() {
    this.getMenuList()
  },
  methods: {
    // 获取菜单list
    getMenuList() {
      this.loading = true
      getMenuList(this.queryParam).then(res => {
        this.tableData = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    // 重置
    resetList() {
      this.queryParam = {
        name: '',
        application: '',
      }
      this.getMenuList()
    },
    // 删除
    handleDelete(row) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMenu({ ids: row.id }).then(res => {
            this.$message.success('删除成功')
            this.getMenuList()
          });
        })
        .catch(() => {});
    },
    handleBack(data) {
      data ? this.getMenuList() : this.resetList();
    },
    // 是否展开所有
    isExpandAll(isExpand) {
      this.rowExpansionAll(this.tableData, isExpand)
    },
    rowExpansionAll(data,isExpansion){
      data.forEach(item=>{
        this.$refs.treeTable.toggleRowExpansion(item,isExpansion);
        if(item.children != undefined && item.children != null){
          this.rowExpansionAll(item.children,isExpansion);
        }
      })
    },
  }
};
</script>

<style lang='less' scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #E8E8E8;
}
.demo-form-inline{
  padding-top: 16px;
  border-bottom: 1px solid #E8E8E8;
}
.table-box{
  padding-top: 24px;
  .operate-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
