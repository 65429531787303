<template>
  <!-- 新增/编辑 对话框 -->
  <el-dialog
    :title="this.editType === 1 ? '新增菜单' : '编辑菜单'"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close='onClose'
  >
    <el-form ref="paramsFormRef" label-width="120px" :model="paramsForm" :rules="rules">
      <el-form-item label="菜单名称" prop="name">
        <el-input v-model.trim="paramsForm.name" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item label="菜单编号" prop="code">
        <el-input v-model.trim="paramsForm.code" placeholder="请输入菜单编号"></el-input>
      </el-form-item>
      <el-form-item>
        <span slot="label"><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>父级编号</span>
        <tree-select :data="treeData"
                     :defaultProps="defaultProps"
                     :nodeKey="nodeKey"
                     :width='375'
                     :checkedKeys="defaultCheckedKeys"
                     @popoverHide="popoverHide"></tree-select>
      </el-form-item>
      <el-form-item label="请求地址" prop="router">
        <el-input v-model.trim="paramsForm.router" placeholder="请输入请求地址"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input :rows="10" v-model.trim="paramsForm.sort" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item label="层级" prop="application">
        <el-input v-model.trim="paramsForm.application" placeholder="请输入层级"></el-input>
      </el-form-item>
      <el-form-item label="是否是菜单" prop="type">
        <el-radio-group v-model="paramsForm.type">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="visible">
        <el-radio-group v-model="paramsForm.visible">
          <el-radio label="Y">启用</el-radio>
          <el-radio label="N">未启用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {editMenu, getMenuTree} from '@/api/main/system/menuMana';
import TreeSelect from '@/components/treeSelect/index.vue';
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入唯一编码', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        router: [
          { required: true, message: '请输入请求地址', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        application: [
          { required: true, message: '请输入层级', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        visible: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      editType: 1,
      treeData: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      nodeKey: 'id',
      paramsForm: {
        id: '',
        name: '',
        pid: '',
        code: '',
        pcode: '',
        sort: '',
        router: '',
        application: '',
        type: 1,
        visible: 'Y',
        openType: 1,
        weight: 2,
      },
      checkedData: null,
    };
  },
  components: {TreeSelect},
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.editType = row ? 2 : 1 // 1 新增 / 2 编辑
      if (row) {
        this.getMenuTreeData(row.pid)
        this.$nextTick(() => {
          this.paramsForm = Object.assign(this.paramsForm, {
            id: row.id,
            name: row.name,
            code: row.code,
            pcode: row.pcode,
            sort: row.sort,
            router: row.router,
            application: row.application,
            type: row.type,
            visible: row.visible,
            pid: row.pid,
            weight: row.weight,
            openType: row.openType,
          });
        });
      }else {
        this.getMenuTreeData()
      }
    },
    // 获取父级节点
    getMenuTreeData(pid) {
      getMenuTree().then(res => {
        this.treeData = res.data
        if(pid) {
          this.defaultCheckedKeys.push(pid);
        }
      })
    },
    // 选择父级
    popoverHide(checkedIds, checkedData) {
      if(checkedData) {
        this.checkedData = checkedData
        this.paramsForm.pid = checkedData.id
      }
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
      this.editType = null
      this.checkedData = null
      this.defaultCheckedKeys = []
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        if(!this.paramsForm.pid) {
          this.$message.error('请选择父级编号')
          return
        }
        this.submitLoading = true;
        // this.paramsForm.pid = 0
        editMenu(this.paramsForm)
          .then(res => {
            const { id } = this.paramsForm;
            this.$message.success(id ? '编辑菜单成功！' : '新增菜单成功！');
            this.$emit('back', id);
            this.onClose();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>
